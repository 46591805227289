import revive_payload_client_nzG0nBUAZs from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GeiJNPQHkD from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_n0pQ70otYT from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lSvW5IqWtL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_A8sqLxQmzk from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PLwZO5BAb2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_y7d7Q0LTb9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_F3JtMhaNBM from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_@vercel+blob@0.22.3_bufferutil@4.0.9_db_xooa6mjgynpcrrys6sp3sdjmkq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1bvMaMpwzs from "/vercel/path0/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/vercel/path0/.nuxt/primevue-plugin.mjs";
import plugin_client_OQv4RUgNVL from "/vercel/path0/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_@babel+parser@7.26.5_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import clickOutside_client_YeoeLf0aWu from "/vercel/path0/plugins/directives/clickOutside.client.ts";
import Vue3Lottie_client_bMZiOS6AB0 from "/vercel/path0/plugins/Vue3Lottie.client.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import autoScroll_client_em46nOeTJc from "/vercel/path0/plugins/autoScroll.client.ts";
import draggable_client_hyqucl8vfz from "/vercel/path0/plugins/draggable.client.ts";
import fullcalendar_client_qAcKSSkGJk from "/vercel/path0/plugins/fullcalendar.client.ts";
import loading_GxQ2Z9Goxa from "/vercel/path0/plugins/loading.ts";
import toast_ysMjUcU7eJ from "/vercel/path0/plugins/toast.ts";
export default [
  revive_payload_client_nzG0nBUAZs,
  unhead_GeiJNPQHkD,
  router_n0pQ70otYT,
  payload_client_lSvW5IqWtL,
  navigation_repaint_client_A8sqLxQmzk,
  check_outdated_build_client_PLwZO5BAb2,
  chunk_reload_client_y7d7Q0LTb9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_F3JtMhaNBM,
  plugin_1bvMaMpwzs,
  primevue_plugin_egKpok8Auk,
  plugin_client_OQv4RUgNVL,
  clickOutside_client_YeoeLf0aWu,
  Vue3Lottie_client_bMZiOS6AB0,
  analytics_client_mtqVw3YRXk,
  autoScroll_client_em46nOeTJc,
  draggable_client_hyqucl8vfz,
  fullcalendar_client_qAcKSSkGJk,
  loading_GxQ2Z9Goxa,
  toast_ysMjUcU7eJ
]